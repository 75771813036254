.container{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.container h3{
    font-size: 27px;
    color: #f9dc5c;
}
.mainDiv{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}
.text{
    width: 50%;
}
.text p{
    font-size: 17px;
}
.image{
    width: 30%;
}

@media (max-width: 1000px){
    .container{
        width: 100%;
        height: 100vh;
    }
    .mainDiv{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
    .image img{
        width: 100%;
        height: 100%;
    }
    .text{
        width: 90%;
    }
}