.container{
    /* width: 100%; */
    height: 100vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}
.topDiv{
    height: 70px;
    /* border: red; */
}
.imageBg{
    background: rgb(249,220,92);
    background: linear-gradient(4deg, rgba(249,220,92,1) 20%, rgba(0,46,57,0) 68%);
    border-radius: 51% 49% 50% 50% / 62% 63% 37% 38%  ;
    border-radius: 73% 27% 50% 50% / 59% 70% 30% 41% ;
    padding: 10px;
}
.hi{
    margin: 5px 0px 5px 20px;
    padding: 2px;
    font-size: 37px;
}
.myname{
    margin: 5px;
    padding: 2px;
    font-size: 40px;
}
.fullstack{
    margin: 5px;
    padding: 2px;
    font-size: 30px;
}
.buttons a{
    text-decoration: none;
    color: inherit;
}
.buttons Button{
    margin: 7px;
}

@media (max-width: 1000px){
    .container{
        /* width: 100%; */
        height: 100vh;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: space-evenly;
    }
    .image{
        width: 100%;
        height: 100%;
    }
    .imageBg{
        width: 80%;
        background: rgb(249,220,92);
        background: linear-gradient(4deg, rgba(249,220,92,1) 20%, rgba(0,46,57,0) 68%);
        border-radius: 51% 49% 50% 50% / 62% 63% 37% 38%  ;
        border-radius: 73% 27% 50% 50% / 59% 70% 30% 41% ;
        padding: 10px;
    }
    .hi{
        margin: 5px 0px 5px 20px;
        padding: 2px;
        font-size: 27px;
    }
    .myname{
        margin: 5px;
        padding: 2px;
        font-size: 30px;
    }
    .fullstack{
        margin: 5px;
        padding: 2px;
        font-size: 20px;
    }
    .buttons{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
    }
}