.App {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* max-width: 1500px;
  margin-left: auto;
  margin-right: auto; */
}
.BodyContainer{
  /* border: 1px solid red; */
  max-width: 1500px;
  margin-left: auto;
  margin-right: auto;
}
.container{
  /* border: 1px solid blue; */
  /*padding-top: 70px;*/
  /* width: 100%; */
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}