.mainDiv{
    padding-bottom: 5px;
    width: 100%;
    background-color: #47829a;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.firstP{
    margin: 7px;
}