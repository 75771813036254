.container{
    /* border: 1px solid blue; */
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}
.container h3{
    font-size: 27px;
    color: #f9dc5c;
}
.mainDiv{
    /* border: 1px solid deeppink; */
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}
.techSkills{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    height: 100%;
}
.listTech{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
}
.softSkills{
    background-color: blue;
    width: 50%;
    height: 100%;
}

@media (max-width: 1000px){
    .container{
        width: 100%;
        height: 100vh;
    }
}