.cardContainer{
    filter: grayscale(100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 200px;
    margin: 10px;
    padding: 10px;
    /* border: 1px solid red; */
}
.cardContainer:hover{
    filter: grayscale(0%);
}
.cardContainer img{
    padding-top: 15px;
    max-height: 170px;
    max-width: 140px;
}
.cardContainer h6{
    font-size: 17px;
}
@media (max-width: 1000px){
    .cardContainer{
        filter: grayscale(100%);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 70px;
        margin: 2px;
        padding: 2px;
        border: 1px solid red;
    }
    .cardContainer img{
        padding-top: 15px;
        max-height: 20px;
        max-width: 20px;
    }
    .cardContainer h6{
        font-size: 7px;
    }
}