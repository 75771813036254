.logoCont{
    cursor: pointer;
}
.logoCont img{
    margin-top: 4px;
    height: 60px;
    width: auto;
}
.linksCont{
    margin-right: 7px;
}
.links{
    margin: 4px;
    cursor: pointer;
    color: white;
}
.selectedLink{
    margin: 4px;
    cursor: pointer;
    color: #f9dc5c;
}
.navbar{
    height: 70px;
}