.container{
    /* border: 1px solid blue; */
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center
}
.container h3{
    font-size: 27px;
    color: #f9dc5c;
}
.mainDiv{
    padding: 20px;
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}
@media (max-width: 1000px){
    .mainDiv{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
    }
}