.container{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.subNavbar{
    height: 70px;
    width: 100%;
}
.mainDiv{
    width: 70%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}
.formContainer{
    display: flex;
    flex-direction: column;
    margin: 10px;
    padding: 10px;
}
.formSubcontainer{
    display: flex;
    flex-direction: row;
}
.formTop{
    display: flex;
    flex-direction: column
}
.contactInfo{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.contactInfo p{
    font-size: 20px;
}
@media (max-width: 1000px){
    .container{
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }
    .mainDiv{
        width: 70%;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: space-around;
    }
    .formSubcontainer {
        border: 1px solid red;
        margin: 0px;
        padding: 0px;
        display: flex;
        flex-direction: column;
    }
}